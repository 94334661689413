<template>
  <div class="student" :class="styles[isColor]" v-if="isShow">
    <van-nav-bar
      class="student-header"
      :title="navTitle"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="student-table">
      <div class="students-header">
        <div
          class="students-cell"
          v-for="(item, index) in headers"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="student-list" v-for="(item, index) in lists" :key="index">
        <div class="students-cell">{{ item.userName }}</div>
        <div class="students-cell">{{ item.sex }}</div>
        <div class="students-cell">{{ item.treeNames }}</div>
        <div class="students-cell">{{ item.mobile }}</div>
      </div>
      <van-empty description="暂无数据" v-if="lists.length === 0" />
    </div>
  </div>
</template>

<script>
import { studentFrom, detailType } from "@/service";
export default {
  name: "student",
  data() {
    return {
      navTitle: "学员名单",
      headers: ["姓名", "性别", "单位及职务", "联系方式"],
      styles: ["peach", "orange", "purple"],
      isColor: 2,
      isShow: false,
      lists: [
        // {
        //   userName: "戴成林",
        //   sex: "男",
        //   treeNames: "赤壁市神山镇党委委员、副镇长、武装部部长",
        //   mobile: "18089620086",
        // },
        // {
        //   userName: "刘文",
        //   sex: "女",
        //   treeNames: "赤壁市神山镇党委委员、副镇长、武装部部长",
        //   mobile: "18089620086",
        // },
        // {
        //   userName: "戴成林",
        //   sex: "男",
        //   treeNames: "赤壁市神山镇党委委员、副镇长、武装部部长",
        //   mobile: "18089620086",
        // },
        // {
        //   userName: "陆国盛",
        //   sex: "男",
        //   treeNames: "赤壁市神山镇党委委员、副镇长、武装部部长",
        //   mobile: "18089620086",
        // },
        // {
        //   userName: "宋静静",
        //   sex: "女",
        //   treeNames: "赤壁市神山镇党委委员、副镇长、武装部部长",
        //   mobile: "18089620086",
        // },
        // {
        //   userName: "王子怡",
        //   sex: "男",
        //   treeNames: "赤壁市神山镇党委委员、副镇长、武装部部长",
        //   mobile: "18089620086",
        // },
        // {
        //   userName: "邵达",
        //   sex: "男",
        //   treeNames: "赤壁市神山镇党委委员、副镇长、武装部部长",
        //   mobile: "18089620086",
        // },
        // {
        //   userName: "陈若华",
        //   sex: "女",
        //   treeNames: "赤壁市神山镇党委委员、副镇长、武装部部长",
        //   mobile: "18089620086",
        // },
      ],
    };
  },
  created() {
    studentFrom(localStorage.getItem("SID")).then((data) => {
      this.lists = data;
      this.isShow = true;
    });
    this.getType();
  },
  methods: {
    async getType() {
      // 获取风格接口
      let res = await detailType(
        localStorage.getItem("SID"),
        "sys_course_style"
      );
      this.isColor = parseInt(res[0].dictValue);
    },
    onClickLeft() {
      this.$router.push({
        path: "/home",
      });
    },
  },
};
</script>

<style lang="less">
.student {
  min-height: 100vh;
  &.peach {
    .students-header {
      background: rgba(255, 156, 132, 1);
    }
    .student-list {
      &:nth-child(2n) {
        background: rgba(255, 232, 225, 1);
      }
      &:nth-child(2n + 1) {
        background: rgba(255, 232, 225, 0.35);
      }
    }
  }
  &.orange {
    .students-header {
      background: rgba(255, 182, 132, 1);
    }
    .student-list {
      &:nth-child(2n) {
        background: rgba(255, 237, 225, 1);
      }
      &:nth-child(2n + 1) {
        background: rgba(255, 237, 225, 0.35);
      }
    }
  }
  &.purple {
    .students-header {
      background: rgba(141, 132, 255, 1);
    }
    .student-list {
      &:nth-child(2n) {
        background: rgba(235, 225, 255, 1);
      }
      &:nth-child(2n + 1) {
        background: rgba(235, 225, 255, 0.35);
      }
    }
  }
  .student-table {
    .students-header {
      display: flex;
      .students-cell {
        font-size: 16px;
        color: #fff;
        &:nth-child(1) {
          border-right: 1px solid #fff;
        }
        &:nth-child(2) {
          border-right: 1px solid #fff;
        }
        &:nth-child(3) {
          border-right: 1px solid #fff;
        }
      }
    }
    .student-list {
      display: flex;
      .students-cell {
        font-size: 15px;
        color: #3f3f3f;
      }
    }
    .students-cell {
      text-align: center;
      padding: 14px 8px;
      width: 0;
      word-break: break-all;
      display: flex;
      justify-content: center;
      align-items: center;
      &:nth-child(1) {
        flex: 2;
        border-right: 1px solid #fff;
      }
      &:nth-child(2) {
        flex: 1.5;
        border-right: 1px solid #fff;
      }
      &:nth-child(3) {
        flex: 3.5;
        border-right: 1px solid #fff;
      }
      &:nth-child(4) {
        flex: 3;
      }
    }
  }
}
</style>
